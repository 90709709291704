// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-danh-muc-danh-muc-js": () => import("./../../../src/components/DanhMuc/DanhMuc.js" /* webpackChunkName: "component---src-components-danh-muc-danh-muc-js" */),
  "component---src-components-post-post-js": () => import("./../../../src/components/Post/Post.js" /* webpackChunkName: "component---src-components-post-post-js" */),
  "component---src-components-product-details-product-details-js": () => import("./../../../src/components/productDetails/productDetails.js" /* webpackChunkName: "component---src-components-product-details-product-details-js" */),
  "component---src-components-tuyen-dung-tuyen-dung-js": () => import("./../../../src/components/TuyenDung/TuyenDung.js" /* webpackChunkName: "component---src-components-tuyen-dung-tuyen-dung-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gioi-thieu-index-js": () => import("./../../../src/pages/gioi-thieu/index.js" /* webpackChunkName: "component---src-pages-gioi-thieu-index-js" */),
  "component---src-pages-huong-dan-index-js": () => import("./../../../src/pages/huong-dan/index.js" /* webpackChunkName: "component---src-pages-huong-dan-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lien-he-index-js": () => import("./../../../src/pages/lien-he/index.js" /* webpackChunkName: "component---src-pages-lien-he-index-js" */),
  "component---src-pages-tin-tuc-index-js": () => import("./../../../src/pages/tin-tuc/index.js" /* webpackChunkName: "component---src-pages-tin-tuc-index-js" */)
}

